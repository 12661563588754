import { PATIENTS_DETAIL_URL } from 'constants/routes'
import React, { useState } from 'react'
import { Column } from 'primereact/column'
import { find, path, pathOr, pipe, propEq } from 'ramda'
import { TableUI } from 'components/Table'
import { getFullName } from 'utils/get'
import { useRedirect } from 'hooks'
import { TPagination } from 'types'
import { Tag } from 'primereact/tag'
import { IconField } from 'primereact/iconfield'
import { InputIcon } from 'primereact/inputicon'
import { InputText } from 'primereact/inputtext'
import { DataTableFilterMeta } from 'primereact/datatable'
import { FilterMatchMode } from 'primereact/api'
import { numberFormat } from 'utils/numberFormat'
import { scrollHeightBase } from 'components/Table/utils'
import { statusColor } from 'types/enums'
import { Button } from 'primereact/button'
import { useStore } from 'react-redux'
import * as API from '../../../constants/api'
import axios from '../../../utils/axios'
import { REPORT_EXCEL } from '../../../constants/api'

const NameBody = (item) => {
  return (
    <span>
      {getFullName(item)}
    </span>
  )
}
const statuses = [
  { id: 'notComplited', name: 'Не выполнено' },
  { id: 'paid', name: 'Оплачен' },
  { id: 'done', name: 'Выполнено' },
  { id: 'active', name: 'Активный' }
]

const PatientsTable = ({ listData, statsData, initialValues, filterAction }) => {
  const [globalFilterValue, setGlobalFilterValue] = useState<string>('')
  const [filters, setFilters] = useState<DataTableFilterMeta>({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  })
  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    let _filters = { ...filters }

    // @ts-ignore
    _filters.global.value = value

    setFilters(_filters)
    setGlobalFilterValue(value)
  }

  const statsDataArr = pathOr([], ['data', 'data'], statsData)
  const value = pathOr([], ['data', 'data'], listData).map((item) => {
    return {
      ...item,
      // @ts-ignore
      statusData: pipe(
        find(propEq(item?.patientId, 'patientId')),
      )(statsDataArr)
    }
  })
  const statusBody = (rowData) => {
    return (
      <div className={'flex '}>
        {statuses.map((i, key) => {
          return (
            <Tag severity={statusColor(i.id)} value={`${i.name} - ${path(['statusData', i.id], rowData)}`} style={{ whiteSpace: 'nowrap' }} key={key} className={'m-2'}/>
          )
        })}
      </div>
    )
  }
  const store = useStore()

  const renderHeader = () => {
    const downloadFile = async () => {
      try {
        const response = await axios(store)
          .get(API.REPORT_EXCEL, {
            params: {
              clinicId: initialValues?.clinicId,
              doctorId: initialValues?.doctorId,
              startDate: filterAction.initialValues?.startDate,
              endDate: filterAction.initialValues?.endDate,
            },
            responseType: 'blob'
          })
        if (response.statusText !== 'OK') {
          throw new Error('Ошибка при скачивании файла')
        }

        // Получаем бинарные данные
        const blob = await response.data

        // Создаем URL для скачивания
        const url = window.URL.createObjectURL(blob)

        // Создаем временный элемент <a> для скачивания
        const a = document.createElement('a')
        a.href = url
        a.download = 'appointments.xlsx' // Имя файла по умолчанию
        document.body.appendChild(a)
        a.click()

        // Удаляем временный элемент после скачивания
        a.remove()
      } catch (error) {
        console.error('Ошибка:', error)
      }
    }
    return (
      <div className="flex flex-wrap gap-2 justify-content-between align-items-center">
        <Button type="button" icon="pi pi-file-pdf" onClick={downloadFile} className="p-button-warning mr-2" data-pr-tooltip="PDF" />
        <IconField iconPosition="left">
          <InputIcon className="pi pi-search" />
          <InputText value={globalFilterValue} onChange={onGlobalFilterChange} placeholder="Введите для поиска" />
        </IconField>
      </div>
    )
  }
  const pagination = path<TPagination>(['data', 'pagination'], listData)

  const gotDetail = useRedirect(PATIENTS_DETAIL_URL)

  return (
    <TableUI
      selectionMode={'single'}
      rowHover={true}
      className={'card'}
      globalFilterFields={['firstName', 'doctor.firstName', 'phoneNumber', 'paid', 'debt']}
      header={renderHeader()}
      filters={filters}
      filterDisplay="menu"
      scrollHeight={scrollHeightBase}
      pagination={pagination}
      onRowClick={({ data }) => gotDetail(data.patientId)}
      loading={statsData.loading || listData.loading}
      value={value}
      lazy={false}
      dataKey="doctorId"
    >
      <Column field="doctor" header="Доктор" body={(item) => getFullName(item.doctor)} />
      <Column field="firstName" header="ФИО" body={NameBody} />
      <Column field="phoneNumber" header="ТЕЛЕФОН" />
      <Column field="paid" header="Оплачено" body={(item) => numberFormat(item.paid)} />
      <Column field="debt" header="Долг" body={(item) => numberFormat(item.debt)} />
      <Column field="status" header="Статусы" body={statusBody} />
    </TableUI>
  )
}

export default PatientsTable
