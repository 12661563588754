import * as ROUTES from 'constants/routes'
import React from 'react'
import AppTopBar from 'components/AppTopBar'
import PermissionWrapper from 'components/Perms/PermissionWrapper'
import { Field, Form } from 'react-final-form'
import { ConfirmPopup } from 'primereact/confirmpopup'
import { groupBy, isEmpty, omit, path, pathOr } from 'ramda'
import { sprintf } from 'sprintf-js'
import { UniversalStaticSelectField } from '../../../../components/Form/Select'
import { useServices } from '../ServicesProvider'
import { TTeethService } from '../../../../types'
import TeethMenuBarUi from './TeethMenuBar/TeethMenuBarUi'
import TeethFields from './TeethFields'
import ServiceActionBar from './ServiceActionBar'
import ServiceTable from './ServiceTable'
import ServicesModal from './ServicesModal'

const ServicesGrid = ({ servicesData, servicesDiagnosisData, updateData, itemData, servicesModalData, listMessagesData }) => {
  const { teethSelected, handleAddServiceTeeth } = useServices()

  const loadingService = servicesData.loading
  const loadingDiagnosis = servicesDiagnosisData.loading
  const services: TTeethService[] = pathOr([], ['data', 'data'], servicesData).map(item => ({
    ...item,
    parentId: item?.parentId || null
  }))
  const grouped = groupBy(product => product.parentId, services)
  function childrenOf (parentId) {
    return (grouped[parentId] || []).map(service => ({
      ...service,
      children: childrenOf(service._id)
    }))
  }
  const serviceTree = childrenOf(null)
  const getService = (data) => data.map(service => {
    const hasChildren = !isEmpty(service.children)
    return [hasChildren ? null : service, ...getService(service.children)]
  }).flat().filter(Boolean)

  const activeServices = getService(serviceTree)

  const disableSelect = isEmpty(teethSelected)

  const patientId = path(['data', 'data', 'patientId'], itemData)
  return (
    <>
      <Form
        onSubmit={() => ({})}
        render={({ values }) => {
          return (
            <>
              <AppTopBar
                breadcrumbs
                way={patientId ? [{ url: sprintf(ROUTES.PATIENTS_DETAIL_URL, patientId), title: 'Анкета' }] : []}
                title={'Процедуры'}
                leftActions={!loadingService && (
                  <Field
                    customOnChange={(option) => handleAddServiceTeeth(omit(['id'], option))}
                    disabled={disableSelect}
                    placeholder={disableSelect ? 'Выберите зуб' : 'Поиск услуг'}
                    name="serviceSearch"
                    list={activeServices}
                    component={UniversalStaticSelectField}
                  />
                )}
              />
              <ServiceActionBar updateData={updateData} values={values}/>
              <TeethMenuBarUi
                servicesModalData={servicesModalData}
                servicesData={servicesData}
                servicesDiagnosisData={servicesDiagnosisData}
              />
              <PermissionWrapper permissions={['READ']}>
                <TeethFields />
              </PermissionWrapper>
              <ConfirmPopup />
              <ServicesModal
                onClose={servicesModalData.onClose}
                open={servicesModalData.open}
                servicesData={servicesData}
                loading={loadingService || loadingDiagnosis}
                servicesDiagnosisData={servicesDiagnosisData}
              />
            </>
          )
        }}
      />
      <ServiceTable itemData={itemData} listMessagesData={listMessagesData}/>
    </>
  )
}

export default ServicesGrid
